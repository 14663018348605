import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContentbi from '../components/ServiceDetailsbi/ServiceDetailsContentbi'
import RelatedServicesbi from '../components/ServiceDetailsbi/RelatedServicesbi'

const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Business Intelligence  "
                homePageText="Home"
                homePageUrl="/"
                activePageText="Service Details"
            />
            <ServiceDetailsContentbi />
            <RelatedServicesbi />
            <Footer />
        </Layout>
    );
}

export default Details