import React from 'react'
import ServiceSidebarbi from './ServiceSidebarbi'
import detailsbi from '../../assets/images/services/services-detailsbi.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentbi = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={detailsbi} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Big Data </span>
                            <h3>About this Service</h3>
                            <p>Business Intelligence (BI) is a single-platform approach to getting data to decision makers across an organization. </p>
                            <p>The vendors in this space (such as Microsoft, Amazon Web Services, SAP, Tableau, Qlik, Teradata, Oracle, IBM and others) are well established and have been focusing on this area for well over 2 decades. The enterprise BI toolset within these platforms provides the ability to complete the following agendas:</p>
                            
                            <li>Dashboarding</li>
                            <li>Operational reporting</li>
                            <li>Data visualization</li>
                            <li>Ad hoc usage</li>
                            <li>Data cleansing</li>
                            <li>Predictive analysis & analytics</li>
                            <li>OLAP concepts</li>
                            <li>Database offerings</li>
                            <li>KPI semantic layers</li>
                            <p>For many organizations, Business Intelligence (BI) has already been established. Others have multiple tools that they have integrated with their existing systems. Enterprise requirements sometimes point to a mandatory commitment to the BI approach, while others do not.</p>
                            <p>Through our experience, we have discovered that large-scale operational reports, data governance, and security are key requirements all pointing to an effective BI Strategy.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>15+ Implementations</li>
                                            <li>12+ Industry Experts</li>
                                            <li>20+ years of experience </li>
                                            

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>From enterprise business intelligence strategy to enterprise analytics consulting, we can help.</p>
                            <p>Diamonds Consulting assists organizations in establishing BI (from both a vendor and best practice standpoint) or weaving departmental BI applications into an enterprise wide BI system.</p>
                            <p>We focus on producing business-impacting results rather than just implementing tools.</p>
                            <h3>Application Areas</h3>
                            <p> Related Industries/Application Domains</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Insurance
                                    </div>
                                </div>



                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Health Care

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>

                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>Oracle</span></li>
                                <li><span>Teradata</span></li>
                                <li><span>DB2</span></li>
                                <li><span>Netezza</span></li>
                                <li><span>MS Azure</span></li>
                                <li><span>AWS</span></li>
                                <li><span>Tableau</span></li>
                                <li><span>OAC</span></li>
                                <li><span>Qlikview</span></li>
                                <li><span>Microstrategy</span></li>
                                <li><span>BusinessObjects</span></li>
                                <li><span>MS Power BI</span></li>
                                <li><span>Informatica</span></li>
                                <li><span>DataStage</span></li>
                                <li><span>ODI</span></li>
                                <li><span>Teradata Utilities</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarbi />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentbi